import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css'
import { i18n } from '@/i18n';
import { initHttpInterceptors } from '@/utils/http';
import '@/assets/font/font.css';
import '@/styles/index.less';

Vue.config.productionTip = false;

Vue.use(Antd);
initHttpInterceptors(store, router, i18n);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
