export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: '安全な支払いページ',
      description: '私たちはあなたの金融情報を安全に暗号化しています',
      methodErrorTips: '申し訳ありません。あなたが選択した国は現在サポートされていません。別の国を選択して再度お試しください。',
      recommendation: '推奨',
      cardNumber: 'カード番号',
      cardName: 'カード名義人',
      cvv: 'CVV',
      expiredDate: '有効期限',
      billingInfo: '請求情報',
      country: '国',
      state: '州',
      firstName: '名',
      lastName: '姓',
      document: '書類',
      city: '市',
      address: '住所',
      streetNumber: '番地',
      zipCode: '郵便番号',
      email: 'メール',
      phone: '電話番号',
      pay: '支払う',
      choosePaymentMethod: '支払い方法を選択',
      continue: '続ける',
      later: '後で',
      returnToMerchant: 'キャンセルして商人に戻る',
      resultToMerchant: '商人に戻る',
      orderId: '注文ID',
      orderAmount: '注文金額',
      billingDescriptor: '請求記述子',
      goodsName: '商品名',
      timeLimit: '時間内に支払いを完了してください。そうしないとページがタイムアウトします',
      otherPaymentMethods: 'その他の支払い方法',
      close: '閉じる',
      paymentErrorTitle: '支払い失敗',
      paymentErrorTip: 'お支払いが正常に処理されませんでした。再度お試しください。',
      paymentFailed: '支払い処理に失敗しました',
      continueShopping: 'ショッピングを続ける',
      paymentSuccess: '支払いが正常に完了しました',
      paymentPending: '支払い送信済み、確認中',
      correctDateTip: '有効な有効期限を入力してください',
      correctCardNumberTip: '有効なカード番号を入力してください',
      correctSecurityCodeTip: '有効なセキュリティコードを入力してください',
      mobileMoneyOperator: 'モバイルマネー事業者',
      bank: '銀行'
    },
    common: {
      uploadImgErrorTip: '画像のみアップロードできます',
      inputMessageTip: '内容を入力してください',
      sendSuccessTip: '送信成功',
      sendErrorTip: '送信失敗',
      inputPlaceholder: '「{field}」を入力してください',
      selectPlaceholder: '「{field}」を選択してください'
    }
  }
};
