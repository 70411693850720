export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Turvallinen maksusivu',
      description: 'Pidämme taloudellisia tietojasi turvallisesti salattuna',
      methodErrorTips: 'Valitettavasti valitsemaasi maata ei tällä hetkellä tueta. Ole hyvä ja valitse toinen maa ja yritä uudelleen.',
      recommendation: 'Suositus',
      cardNumber: 'Kortin numero',
      cardName: 'Kortilla oleva nimi',
      cvv: 'CVV',
      expiredDate: 'Vanhenemispäivä',
      billingInfo: 'Laskutustiedot',
      country: 'Maa',
      state: 'Osavaltio',
      firstName: 'Etunimi',
      lastName: 'Sukunimi',
      document: 'Dokumentti',
      city: 'Kaupunki',
      address: 'Osoite',
      streetNumber: 'Kadun numero',
      zipCode: 'Postinumero',
      email: 'Sähköposti',
      phone: 'Puhelinnumero',
      pay: 'Maksa',
      choosePaymentMethod: 'Valitse maksutapa',
      continue: 'Jatka',
      later: 'Myöhemmin',
      returnToMerchant: 'Peruuta ja palaa kauppiaaseen',
      resultToMerchant: 'Palaa kauppiaaseen',
      orderId: 'Tilausnumero',
      orderAmount: 'Tilausmäärä',
      billingDescriptor: 'Laskutuskuvaus',
      goodsName: 'Tuotenimi',
      timeLimit: 'Varoitus: Suorita maksu määräajan kuluessa, muuten sivu vanhenee.',
      otherPaymentMethods: 'Muu maksutavat',
      close: 'Sulje',
      paymentErrorTitle: 'Maksaminen epäonnistui',
      paymentErrorTip: 'Maksusi ei käsitelty onnistuneesti. Yritä uudelleen.',
      paymentFailed: 'Maksuprosessi epäonnistui',
      continueShopping: 'Jatka shoppailua',
      paymentSuccess: 'Maksuprosessi onnistui',
      paymentPending: 'Maksu lähetetty, vahvistetaan',
      correctDateTip: 'Anna kelvollinen vanhenemispäivämäärä',
      correctCardNumberTip: 'Anna kelvollinen korttinumero',
      correctSecurityCodeTip: 'Ole hyvä ja syötä voimassaoleva turvakoodi',
      mobileMoneyOperator: 'Matkapuhelinrahan operaattori',
      bank: 'Pankki'
    },
    common: {
      uploadImgErrorTip: 'Vain kuvia voi ladata',
      inputMessageTip: 'Ole hyvä ja täytä sisältö',
      sendSuccessTip: 'Lähetys onnistui',
      sendErrorTip: 'Lähetystä epäonnistui',
      inputPlaceholder: 'Ole hyvä ja syötä {field}',
      selectPlaceholder: 'Ole hyvä ja valitse {field}'
    }
  }
};
