<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { loadLanguageAsync } from '@/i18n'
import { Languages } from '@/enums/language'

export default {
  mounted() {
    const saveLang = window.localStorage.getItem('lang')
    const lang = saveLang && saveLang !== 'null' ? saveLang : (navigator.language || navigator.browserLanguage).toLowerCase()
    const langItem = Languages.find((item) => String(lang).includes(item.text))
    if (langItem) {
      loadLanguageAsync(langItem.value)
    } else {
      loadLanguageAsync('en')
    }
  }
}
</script>
<style lang="less">
#app {
  height: 100%;
  width: 100%;
}
</style>
