export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'صفحة الدفع الآمنة',
      description: 'نحن نحتفظ بمعلوماتك المالية مشفرة بأمان',
      methodErrorTips: '.نأسف، الدولة التي اخترتها غير مدعومة حالياً. يرجى اختيار دولة أخرى ومحاولة مرة أخرى.',
      recommendation: 'توصية',
      cardNumber: 'رقم البطاقة',
      cardName: 'اسم صاحب البطاقة',
      cvv: 'CVV',
      expiredDate: 'تاريخ انتهاء الصلاحية',
      billingInfo: 'معلومات الفواتير',
      country: 'بلد',
      state: 'ولاية',
      firstName: 'الاسم الأول',
      lastName: 'الاسم الأخير',
      document: 'وثيقة',
      city: 'مدينة',
      address: 'عنوان',
      streetNumber: 'رقم الشارع',
      zipCode: 'رمز البريد',
      email: 'البريد الإلكتروني',
      phone: 'رقم الهاتف',
      pay: 'دفع',
      choosePaymentMethod: 'اختر طريقة الدفع',
      continue: 'استمرار',
      later: 'لاحقا',
      returnToMerchant: 'إلغاء والعودة إلى التاجر',
      resultToMerchant: 'العودة إلى التاجر',
      orderId: 'رقم الطلب',
      orderAmount: 'مبلغ الطلب',
      billingDescriptor: 'وصف الفاتورة',
      goodsName: 'اسم السلعة',
      timeLimit: 'يرجى إكمال الدفع خلال الفترة المحددة، وإلا سيكون الصفحة مهلة',
      otherPaymentMethods: 'طرق الدفع الأخرى',
      close: 'إغلاق',
      paymentErrorTitle: 'فشل الدفع',
      paymentErrorTip: 'لم يتم معالجة دفعتك بنجاح. يرجى المحاولة مرة أخرى.',
      paymentFailed: 'فشل عملية الدفع',
      continueShopping: 'استمر في التسوق',
      paymentSuccess: 'تمت عملية الدفع بنجاح',
      paymentPending: 'تم إرسال الدفع، مؤكد',
      correctDateTip: 'يرجى إدخال تاريخ انتهاء صالح',
      correctCardNumberTip: 'يرجى إدخال رقم بطاقة صالح',
      correctSecurityCodeTip: 'يرجى إدخال رمز الأمان الصحيح',
      mobileMoneyOperator: 'مشغل المال المحمول',
      bank: 'بنك'
    },
    common: {
      uploadImgErrorTip: 'يُمكن فقط رفع الصور',
      inputMessageTip: 'يرجى ملء المحتوى',
      sendSuccessTip: 'تم الإرسال بنجاح',
      sendErrorTip: 'فشل الإرسال',
      inputPlaceholder: 'يرجى إدخال {field}',
      selectPlaceholder: 'يرجى الاختيار {field}'
    }
  }
};
